import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Banner from "../components/Index/Banner"
import { Link } from "gatsby"
import RegularBannerFutures from "../components/AboutUs/RegularBannerFutures"
import ReversedBannerFeatures from "../components/AboutUs/ReversedBannerFeatures"
import ReversedBanner from "../components/AboutUs/ReversedBanner"
import VerticalBanner from "../components/AboutUs/VerticalBanner"
import PeopleCentric from "../components/Features/PeopleCentric"
import HorizontalCards from "../components/Features/HorizontalCards"
import VisuallyAppealing from "../components/Features/VisuallyAppealing"
import bannerImg from "../assets/images/features/banner-features.webp"
import calendarImg from "../assets/images/features/iPad-mini.webp"
import laterBoardImg from "../assets/images/features/later-board.webp"
import unifiedFoldersImg from "../assets/images/features/unified-folders-new.webp"
import instantPushPhone from "../assets/images/features/instant-push-phone.webp"
import clustersBoyImg from "../assets/images/features/clusters-boy.webp"
import unlimitedAccountImg from "../assets/images/features/unlimited-accounts-any-protocol-new.webp"
import magicSyncImg from "../assets/images/features/magic-sync-feature-a.webp"
import securityPrivacyImg from "../assets/images/features/security-privacy.webp"
import visualDesktopOneImg from "../assets/images/features/visually-appealing-desktop-1.webp"
import visualDesktopTwoImg from "../assets/images/features/visually-appealing-desktop-2.webp"
import visualMobileOneImg from "../assets/images/features/visually-appealing-mobile-1.webp"
import visualMobileTwoImg from "../assets/images/features/visually-appealing-mobile-2.webp"
import darkThemeImg from "../assets/images/features/dark-theme-img.webp"
import peopleCentricImg from "../assets/images/features/people-centric.webp"
import gemEmailAiImg from "../assets/images/features/gemEmailAi-noBorder.webp"
import SEO from "../components/App/SEO"
import CaseStudySlider from "../components/AboutUs/CaseStudySlider"
import { graphql } from "gatsby"
import ReversedVerticalBanner from "../components/AboutUs/ReversedVerticalBanner"
import ClickAnalytics from "../components/ComeBack/ClickAnalytics"

const banner = {
  title: (
    <h1>
      {" "}
      <div>
        Your <span>all in One</span>{" "}
      </div>{" "}
      <div>Messaging platform</div>{" "}
    </h1>
  ),
  subtitle: "All your communication needs in one app ",
  image: bannerImg,
}

const calendar = {
  title: (
    <h1>
      Integrated <span>Calendar</span>
    </h1>
  ),
  subtitle:
    "Access your Calendar and plan events right from within BlueMail. Your Calendar is automatically synced and updated across all your devices on the fly.",
  image: calendarImg,
  link: "/calendar/",
}

const unifiedFolders = {
  title: (
    <h1>
      <span>Unified </span> Folders
    </h1>
  ),
  subtitle:
    "Emails from all your accounts are combined and displayed in unique folders so you can view and perform actions on multiple accounts at the same time.",
  image: unifiedFoldersImg,
  class: "",
  link: "/features-functions/unified-inbox/",
}

const instantPush = {
  title: (
    <h1>
      <span>Instant</span> Push
    </h1>
  ),
  subtitle:
    "BlueMail intelligently pushes your emails so they arrive to your device at instantaneous speeds saving valuable time.",
  image: instantPushPhone,
  class: "",
  link: "/features-functions/instant-push/",
}

const clusters = {
  title: (
    <h1>
      <span>Clusters</span>
    </h1>
  ),
  subtitle:
    "Dynamically and smartly organize your Inbox by sender & group, elegantly bringing together emails from People, Groups, and Services to declutter your mailbox.",
  image: clustersBoyImg,
  class: "",
  link: "/features-functions/clusters/",
}

const laterBoard = {
  title: (
    <h1>
      <span>Later </span> Board
    </h1>
  ),
  subtitle:
    "Stay on top of your mailbox with a task board for your emails. Mark emails to “Read” or “Reply Later,” and Get Stuff Done at your convenience.",
  image: laterBoardImg,
  link: "/blog/later-board/",
}

const unlimitedAccount = {
  title: (
    <h1>
      <div>
        <span>Unlimited</span> accounts. <span>Any</span> Protocol
      </div>
    </h1>
  ),
  subtitle:
    "BlueMail is capable of managing an unlimited number of accounts from practically every provider and supports the IMAP, SMTP, Exchange ActiveSync, EWS, and POP3 protocols.",
  image: unlimitedAccountImg,
  link: "/blog/later-board/" /*hidden from css */,
}

const magicSync = {
  title: (
    <h1 style={{ textTransform: "none" }}>
      <span>MagicSync</span>
    </h1>
  ),
  subtitle:
    "Backup, restore, and securely transfer your accounts and settings between all your devices. It’s a breeze to set up BlueMail on a new device or keep several existing devices in sync.",
  image: magicSyncImg,
  link: "/blog/magicsync-staying-productive-on-the-go/",
}

const peopleCentric = {
  title: <h1>People - Centric</h1>,
  subtitle:
    "Email is all about communication and bringing people together. Simply flip a switch to feature emails from people at the center of your mailbox.",
  image: peopleCentricImg,
  link: "/features-functions/people-centric/",
}

const securityPrivacy = {
  title: (
    <h1>
      <span>Privacy</span> & <span>Security</span>
    </h1>
  ),
  subtitle:
    "At BlueMail, the privacy and security of our users is a top priority. Our state-of-the-art technological architecture allows us to deliver safer top-tier email and calendar services for you and your organization, while still providing a great user experience throughout.",
  image: securityPrivacyImg,
  link: "/security/",
}

const generativeAi = {
  title: (
    <h1>
      <span>Generative</span> Email AI
    </h1>
  ),
  subtitle:
    "BlueMail GemAI is an email assistant that can help you use emails faster and more efficiently. BlueMail GemAI is powered by ChatGPT to streamline your email workflow and increase your productivity. Whether you're a busy professional or just looking to optimize your communication, artificial intelligence is the perfect solution for you.",
  image: gemEmailAiImg,
  link: "/features-functions/generative-email/",
}

const Features = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="What Makes BlueMail Different? | BlueMail App"
        postDescription="BlueMail is feature-rich email application, which includes many features and functions from IMAP, Exchange, Push, Multiple-accounts and many more."
        postImage="/img/OG/og_image-features-functions.png"
        postURL="features-functions"
        postSEO
      />
      <Navbar />
      <div className="feature-container">
        <div className="banner">
          <Banner banner={banner} />
        </div>
        <div className="generativeAi">
          <ReversedBannerFeatures data={generativeAi} />
        </div>
        <div className="calendar">
          <RegularBannerFutures data={calendar} />
        </div>
        <div className="instant-push">
          <VerticalBanner data={instantPush} />
        </div>
        <div className="unified-clusters">
          <ReversedBannerFeatures data={unifiedFolders} />
          <div className="clusters-box">
            <RegularBannerFutures data={clusters} />
          </div>
        </div>
        <div className="feature-unlimited">
          <ReversedVerticalBanner data={unlimitedAccount} />
        </div>
        <div className="magic-later">
          <RegularBannerFutures data={magicSync} />
          <ReversedBannerFeatures data={laterBoard} />
        </div>
        <div className="people-centric">
          <PeopleCentric data={peopleCentric} />
        </div>
        <div className="security-privacy">
          <RegularBannerFutures data={securityPrivacy} />
        </div>
        <div className="mobile-visually-appealing">
          <VisuallyAppealing />
        </div>
        <div className="visually-appealing">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>visually appealing</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col vApp-01">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-xs-12 text-right">
                        <img
                          className=""
                          src={visualDesktopOneImg}
                          alt="visual appealing"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-xs-12 text-left">
                        <img
                          className="m-one"
                          src={visualMobileOneImg}
                          alt="visual appealing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col box-2">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-xs-12 text-right">
                        <img
                          className="m-two"
                          src={visualMobileTwoImg}
                          alt="visual appealing"
                        />
                      </div>
                      <div className="col-lg-8 col-md-8 col-xs-12 text-left">
                        <img
                          className="d-two"
                          src={visualDesktopTwoImg}
                          alt="visual appealing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dark-theme">
          <Link to="/features-functions/dark-theme/">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h1>Dark theme</h1>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img
                    className="dark-img"
                    src={darkThemeImg}
                    alt="dark theme"
                  />
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="much-widget">
          <HorizontalCards />
          <CaseStudySlider />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Features

export const query = graphql`
  query FeaturesPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
